<div class="list flex flex-col gap-24 mt-40 p-40">
  <div class="list__item flex gap-16 items-center">
    <span>Button primary: </span>
    <app-btn [title]="'Primary button'" />
  </div>
  <div class="list__item flex gap-16 items-center">
    <span>Button cancel: </span>
    <app-btn [title]="'Primary button'" [type]="'secondary'" />
  </div>
  <div class="list__item flex gap-16 items-center">
    <span>Input: </span>
    <app-input [formControl]="inputControl" />
  </div>
  <div class="list__item flex gap-16 items-center">
    <span>Checkbox: </span>
    <app-checkbox [formControl]="checkboxControl" />
  </div>
</div>
