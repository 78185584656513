import { BtnComponent } from './component/btn/btn.component'
import { CheckboxComponent } from './component/checkbox/checkbox.component'
import { InputComponent } from './component/input/input.component'

import { Component, DestroyRef, OnInit } from '@angular/core'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { FormControl, ReactiveFormsModule } from '@angular/forms'

@Component({
  selector: 'app-ui-kit',
  standalone: true,
  imports: [BtnComponent, InputComponent, ReactiveFormsModule, CheckboxComponent],
  templateUrl: './ui-kit.component.html',
  styleUrl: './ui-kit.component.scss',
})
export class UiKitComponent implements OnInit {
  inputControl: FormControl<string> = new FormControl<string>('', { nonNullable: true })
  checkboxControl: FormControl<boolean> = new FormControl<boolean>(false, { nonNullable: true })

  constructor(private destroy: DestroyRef) {}

  ngOnInit(): void {
    this.checkboxControl.valueChanges.pipe(takeUntilDestroyed(this.destroy)).subscribe(() => {})
  }
}
